const countriesS4 = [
    "NA",
    "GU",
    "PR",
    "ES",
    "MX",
    "TO",
    "ER",
    "KR",
    "BQ-BO",
    "MW",
    "CG",
    "GB",
    "NE",
    "BN",
    "GL",
    "MA",
    "KG",
    "CK",
    "RU",
    "MU",
    "VA",
    "DE",
    "PM",
    "IE",
    "XK",
    "CN",
    "KY",
    "SC",
    "RW",
    "LU",
    "HN",
    "OM",
    "BH",
    "GB-NIR",
    "JP",
    "LT",
    "MD",
    "AL",
    "MZ",
    "ID",
    "KW",
    "AE",
    "GI",
    "BS",
    "TT",
    "VE",
    "SY",
    "MV",
    "DZ",
    "PT",
    "KI",
    "TM",
    "SE",
    "GT",
    "BT",
    "AT",
    "EG",
    "TG",
    "SM",
    "PK",
    "MY",
    "TK",
    "IQ",
    "RS",
    "DK",
    "TH",
    "CA",
    "ET",
    "SK",
    "VI",
    "LK",
    "MP",
    "SJ",
    "DJ",
    "SD",
    "GS",
    "MF",
    "BQ-SE",
    "FM",
    "CX",
    "LB",
    "GM",
    "BY",
    "MG",
    "BD",
    "CD",
    "GW",
    "PL",
    "EE",
    "TW",
    "JE",
    "MH",
    "GB-ENG",
    "SX",
    "GF",
    "PF",
    "YE",
    "DM",
    "NU",
    "SH",
    "MK",
    "AS",
    "AM",
    "QA",
    "GQ",
    "CY",
    "BO",
    "KZ",
    "KH",
    "PW",
    "ML",
    "HR",
    "RE",
    "SZ",
    "KN",
    "CU",
    "FO",
    "BW",
    "LC",
    "AR",
    "FJ",
    "TJ",
    "GY",
    "SL",
    "ZA",
    "BM",
    "MS",
    "CF",
    "IN",
    "CH",
    "SO",
    "SA",
    "BF",
    "MT",
    "SG",
    "JO",
    "NZ",
    "AU",
    "SI",
    "MQ",
    "NP",
    "HK",
    "UY",
    "NI",
    "VN",
    "IS",
    "AZ",
    "VU",
    "BE",
    "PY",
    "UZ",
    "BB",
    "SN",
    "CO",
    "BJ",
    "BG",
    "VC",
    "YT",
    "SR",
    "PA",
    "ST",
    "LY",
    "GN",
    "GD",
    "GP",
    "IL",
    "IR",
    "TL",
    "CW",
    "LS",
    "VG",
    "WS",
    "NR",
    "PG",
    "BR",
    "WF",
    "MR",
    "SV",
    "US",
    "ZM",
    "TR",
    "AI",
    "TC",
    "PS",
    "MN",
    "SB",
    "IO",
    "ME",
    "CM",
    "TZ",
    "GR",
    "KP",
    "NL",
    "HT",
    "LA",
    "NO",
    "AD",
    "NG",
    "TD",
    "LI",
    "TF",
    "IM",
    "PE",
    "FK",
    "CV",
    "AG",
    "NC",
    "LR",
    "MM",
    "JM",
    "PN",
    "BI",
    "DO",
    "KM",
    "TN",
    "GE",
    "PH",
    "IT",
    "TV",
    "GH",
    "BA",
    "AF",
    "GG",
    "UA",
    "BZ",
    "KE",
    "UG",
    "SS",
    "AW",
    "MC",
    "LV",
    "FR",
    "CL",
    "MO",
    "GB-WLS",
    "AO",
    "BQ-SA",
    "NF",
    "CI",
    "GA",
    "ZW",
    "BL",
    "CC",
    "CZ",
    "GB-SCT",
    "EH",
    "CR",
    "HU",
    "FI",
    "AX",
    "RO",
    "EC"
];

const countriesS3 = [
    'SD',
    'TO',
    'FI',
    'UA',
    'BH',
    'PA',
    'KR',
    'TN',
    'GQ',
    'GB-ENG',
    'FR',
    'CF',
    'DO',
    'AO',
    'NU',
    'SN',
    'KI',
    'CY',
    'XK',
    'MG',
    'SM',
    'PM',
    'VE',
    'NL',
    'AX',
    'CK',
    'BO',
    'TM',
    'AZ',
    'ET',
    'LC',
    'ME',
    'AF',
    'TC',
    'ZW',
    'MK',
    'CL',
    'FM',
    'MH',
    'RS',
    'HT',
    'SE',
    'NZ',
    'PH',
    'MC',
    'GU',
    'KE',
    'SV',
    'AU',
    'SB',
    'BQ-BO',
    'AL',
    'PR',
    'TV',
    'JP',
    'MZ',
    'BS',
    'GP',
    'CR',
    'SJ',
    'VC',
    'IS',
    'CI',
    'PW',
    'KG',
    'CA',
    'BY',
    'MX',
    'LA',
    'MV',
    'JM',
    'GB-SCT',
    'EE',
    'KH',
    'CH',
    'TZ',
    'NP',
    'KW',
    'IE',
    'LY',
    'SR',
    'CU',
    'PK',
    'GA',
    'FJ',
    'HU',
    'VG',
    'HN',
    'IQ',
    'ZA',
    'ES',
    'TH',
    'CO',
    'SI',
    'GH',
    'GW',
    'FK',
    'CM',
    'MF',
    'MN',
    'IR',
    'TD',
    'GI',
    'TK',
    'PN',
    'MD',
    'RE',
    'KN',
    'BN',
    'SH',
    'IT',
    'TT',
    'BR',
    'ML',
    'AE',
    'CN',
    'GL',
    'AW',
    'MY',
    'BJ',
    'CX',
    'IN',
    'GY',
    'BF',
    'AG',
    'GM',
    'AD',
    'MR',
    'TR',
    'TW',
    'AS',
    'KY',
    'WF',
    'CZ',
    'BL',
    'DK',
    'AR',
    'CV',
    'PT',
    'FO',
    'TF',
    'GB-WLS',
    'SO',
    'AM',
    'US',
    'EG',
    'CG',
    'GR',
    'BQ-SE',
    'VU',
    'GD',
    'ZM',
    'CC',
    'BG',
    'BA',
    'SY',
    'LB',
    'PE',
    'LK',
    'MQ',
    'BI',
    'LR',
    'PL',
    'SK',
    'TL',
    'CW',
    'EC',
    'TJ',
    'NE',
    'HK',
    'LI',
    'RU',
    'NC',
    'SC',
    'DM',
    'LS',
    'GG',
    'CD',
    'TG',
    'LU',
    'KM',
    'DJ',
    'SL',
    'MM',
    'SZ',
    'EH',
    'KP',
    'GN',
    'QA',
    'AI',
    'IL',
    'NR',
    'AT',
    'JE',
    'VN',
    'BW',
    'GS',
    'GF',
    'RW',
    'HR',
    'BQ-SA',
    'DZ',
    'VA',
    'OM',
    'MW',
    'PF',
    'BT',
    'NA',
    'BZ',
    'ST',
    'JO',
    'NF',
    'BD',
    'MP',
    'UG',
    'BM',
    'IO',
    'UZ',
    'LV',
    'RO',
    'PY',
    'SA',
    'SG',
    'SS',
    'NI',
    'NG',
    'MA',
    'UY',
    'SX',
    'LT',
    'MO',
    'ID',
    'NO',
    'WS',
    'KZ',
    'MS',
    'YT',
    'VI',
    'YE',
    'IM',
    'GE',
    'PG',
    'GB-NIR',
    'GT',
    'BE',
    'DE',
    'BB',
    'MT',
    'GB',
    'ER',
    'PS',
    'MU'
];

// Full List (Alphabetised)
const countriesAlpha = [
    {
        'name': 'Afghanistan',
        'aliases': [],
        'iso2': 'AF',
        'filter': 'afghanistan',
        'capital': {
            'name': 'Kabul',
            'geoPt': [
                34.31,
                69.11
            ]
        }
    },
    {
        'name': 'Albania',
        'aliases': [],
        'iso2': 'AL',
        'filter': 'albania',
        'capital': {
            'name': 'Tirana',
            'geoPt': [
                41.19,
                19.49
            ]
        }
    },
    {
        'name': 'Algeria',
        'aliases': [],
        'iso2': 'DZ',
        'filter': 'algeria',
        'capital': {
            'name': 'Algiers',
            'geoPt': [
                36.45,
                3.03
            ]
        }
    },
    {
        'name': 'American Samoa',
        'aliases': [],
        'iso2': 'AS',
        'filter': 'american samoa',
        'capital': {
            'name': 'Pago Pago',
            'geoPt': [
                -14.16,
                -170.42
            ]
        }
    },
    {
        'name': 'Andorra',
        'aliases': [],
        'iso2': 'AD',
        'filter': 'andorra',
        'capital': {
            'name': 'Andorra la Vella',
            'geoPt': [
                42.3,
                1.31
            ]
        }
    },
    {
        'name': 'Angola',
        'aliases': [],
        'iso2': 'AO',
        'filter': 'angola',
        'capital': {
            'name': 'Luanda',
            'geoPt': [
                -8.5,
                13.14
            ]
        }
    },
    {
        'name': 'Anguilla',
        'aliases': [],
        'iso2': 'AI',
        'filter': 'anguilla',
        'capital': {
            'name': 'The Valley',
            'geoPt': [
                18.13,
                -63.03
            ]
        }
    },
    {
        'name': 'Antigua and Barbuda',
        'aliases': [],
        'iso2': 'AG',
        'filter': 'antigua and barbuda',
        'capital': {
            'name': 'St. John\'s',
            'geoPt': [
                17.07,
                -61.51
            ]
        }
    },
    {
        'name': 'Argentina',
        'aliases': [],
        'iso2': 'AR',
        'filter': 'argentina',
        'capital': {
            'name': 'Buenos Aires',
            'geoPt': [
                -34.36,
                -58.4
            ]
        }
    },
    {
        'name': 'Armenia',
        'aliases': [],
        'iso2': 'AM',
        'filter': 'armenia',
        'capital': {
            'name': 'Yerevan',
            'geoPt': [
                40.1,
                44.3
            ]
        }
    },
    {
        'name': 'Aruba',
        'aliases': [],
        'iso2': 'AW',
        'filter': 'aruba',
        'capital': {
            'name': 'Oranjestad',
            'geoPt': [
                12.31,
                -70.02
            ]
        }
    },
    {
        'name': 'Australia',
        'aliases': [],
        'iso2': 'AU',
        'filter': 'australia',
        'capital': {
            'name': 'Canberra',
            'geoPt': [
                -35.17,
                149.13
            ]
        }
    },
    {
        'name': 'Austria',
        'aliases': [],
        'iso2': 'AT',
        'filter': 'austria',
        'capital': {
            'name': 'Vienna',
            'geoPt': [
                48.12,
                16.22
            ]
        }
    },
    {
        'name': 'Azerbaijan',
        'aliases': [],
        'iso2': 'AZ',
        'filter': 'azerbaijan',
        'capital': {
            'name': 'Baku',
            'geoPt': [
                40.23,
                49.52
            ]
        }
    },
    {
        'name': 'Bahamas',
        'aliases': [],
        'iso2': 'BS',
        'filter': 'bahamas',
        'capital': {
            'name': 'Nassau',
            'geoPt': [
                25.05,
                -77.21
            ]
        }
    },
    {
        'name': 'Bahrain',
        'aliases': [],
        'iso2': 'BH',
        'filter': 'bahrain',
        'capital': {
            'name': 'Manama',
            'geoPt': [
                26.14,
                50.34
            ]
        }
    },
    {
        'name': 'Bangladesh',
        'aliases': [],
        'iso2': 'BD',
        'filter': 'bangladesh',
        'capital': {
            'name': 'Dhaka',
            'geoPt': [
                23.43,
                90.24
            ]
        }
    },
    {
        'name': 'Barbados',
        'aliases': [],
        'iso2': 'BB',
        'filter': 'barbados',
        'capital': {
            'name': 'Bridgetown',
            'geoPt': [
                13.06,
                -59.37
            ]
        }
    },
    {
        'name': 'Belarus',
        'aliases': [],
        'iso2': 'BY',
        'filter': 'belarus',
        'capital': {
            'name': 'Minsk',
            'geoPt': [
                53.54,
                27.34
            ]
        }
    },
    {
        'name': 'Belgium',
        'aliases': [],
        'iso2': 'BE',
        'filter': 'belgium',
        'capital': {
            'name': 'Brussels',
            'geoPt': [
                50.5,
                4.2
            ]
        }
    },
    {
        'name': 'Belize',
        'aliases': [],
        'iso2': 'BZ',
        'filter': 'belize',
        'capital': {
            'name': 'Belmopan',
            'geoPt': [
                17.15,
                -88.46
            ]
        }
    },
    {
        'name': 'Benin',
        'aliases': [],
        'iso2': 'BJ',
        'filter': 'benin',
        'capital': {
            'name': 'Porto-Novo',
            'geoPt': [
                6.29,
                2.37
            ]
        }
    },
    {
        'name': 'Bermuda',
        'aliases': [],
        'iso2': 'BM',
        'filter': 'bermuda',
        'capital': {
            'name': 'Hamilton',
            'geoPt': [
                32.17,
                -64.47
            ]
        }
    },
    {
        'name': 'Bhutan',
        'aliases': [],
        'iso2': 'BT',
        'filter': 'bhutan',
        'capital': {
            'name': 'Thimphu',
            'geoPt': [
                27.29,
                89.36
            ]
        }
    },
    {
        'name': 'Bolivia',
        'aliases': [],
        'iso2': 'BO',
        'filter': 'bolivia',
        'capital': {
            'name': 'Sucre',
            'geoPt': [
                -16.3,
                -68.09
            ]
        }
    },
    {
        'name': 'Bonaire',
        'aliases': [],
        'iso2': 'BQ-BO',
        'filter': 'bonaire',
        'capital': {
            'name': 'Kralendijk',
            'geoPt': [
                12.14,
                -68.26
            ]
        }
    },
    {
        'name': 'Bosnia and Herzegovina',
        'aliases': [],
        'iso2': 'BA',
        'filter': 'bosnia and herzegovina',
        'capital': {
            'name': 'Sarajevo',
            'geoPt': [
                43.52,
                18.25
            ]
        }
    },
    {
        'name': 'Botswana',
        'aliases': [],
        'iso2': 'BW',
        'filter': 'botswana',
        'capital': {
            'name': 'Gaborone',
            'geoPt': [
                -24.45,
                25.55
            ]
        }
    },
    {
        'name': 'Brazil',
        'aliases': [],
        'iso2': 'BR',
        'filter': 'brazil',
        'capital': {
            'name': 'Brasilia',
            'geoPt': [
                -15.47,
                -47.55
            ]
        }
    },
    {
        'name': 'British Indian Ocean Territory',
        'aliases': [],
        'iso2': 'IO',
        'filter': 'british indian ocean territory',
        'capital': {
            'name': 'Diego Garcia',
            'geoPt': [
                -7.27,
                72.36
            ]
        }
    },
    {
        'name': 'British Virgin Islands',
        'aliases': [],
        'iso2': 'VG',
        'filter': 'british virgin islands',
        'capital': {
            'name': 'Road Town',
            'geoPt': [
                18.42,
                -64.61
            ]
        }
    },
    {
        'name': 'Brunei',
        'aliases': [],
        'iso2': 'BN',
        'filter': 'brunei',
        'capital': {
            'name': 'Bandar Seri Begawan',
            'geoPt': [
                4.53,
                114.56
            ]
        }
    },
    {
        'name': 'Bulgaria',
        'aliases': [],
        'iso2': 'BG',
        'filter': 'bulgaria',
        'capital': {
            'name': 'Sofia',
            'geoPt': [
                42.41,
                23.19
            ]
        }
    },
    {
        'name': 'Burkina Faso',
        'aliases': [],
        'iso2': 'BF',
        'filter': 'burkina faso',
        'capital': {
            'name': 'Ouagadougou',
            'geoPt': [
                12.22,
                -1.31
            ]
        }
    },
    {
        'name': 'Burundi',
        'aliases': [],
        'iso2': 'BI',
        'filter': 'burundi',
        'capital': {
            'name': 'Gitega',
            'geoPt': [
                -3.22,
                29.21
            ]
        }
    },
    {
        'name': 'Cabo Verde',
        'aliases': [
            'Cape Verde'
        ],
        'iso2': 'CV',
        'filter': 'cabo verde cape verde',
        'capital': {
            'name': 'Praia',
            'geoPt': [
                14.93,
                -23.51
            ]
        }
    },
    {
        'name': 'Cambodia',
        'aliases': [],
        'iso2': 'KH',
        'filter': 'cambodia',
        'capital': {
            'name': 'Phnom Penh',
            'geoPt': [
                11.33,
                104.55
            ]
        }
    },
    {
        'name': 'Cameroon',
        'aliases': [],
        'iso2': 'CM',
        'filter': 'cameroon',
        'capital': {
            'name': 'Yaounde',
            'geoPt': [
                3.52,
                11.31
            ]
        }
    },
    {
        'name': 'Canada',
        'aliases': [],
        'iso2': 'CA',
        'filter': 'canada',
        'capital': {
            'name': 'Ottawa',
            'geoPt': [
                45.25,
                -75.42
            ]
        }
    },
    {
        'name': 'Cayman Islands',
        'aliases': [],
        'iso2': 'KY',
        'filter': 'cayman islands',
        'capital': {
            'name': 'George Town',
            'geoPt': [
                19.18,
                -81.23
            ]
        }
    },
    {
        'name': 'Central African Republic',
        'aliases': [],
        'iso2': 'CF',
        'filter': 'central african republic',
        'capital': {
            'name': 'Bangui',
            'geoPt': [
                4.22,
                18.35
            ]
        }
    },
    {
        'name': 'Chad',
        'aliases': [],
        'iso2': 'TD',
        'filter': 'chad',
        'capital': {
            'name': 'N\'Djamena',
            'geoPt': [
                12.06,
                15.02
            ]
        }
    },
    {
        'name': 'Chile',
        'aliases': [],
        'iso2': 'CL',
        'filter': 'chile',
        'capital': {
            'name': 'Santiago',
            'geoPt': [
                -33.27,
                -70.4
            ]
        }
    },
    {
        'name': 'China',
        'aliases': [],
        'iso2': 'CN',
        'filter': 'china',
        'capital': {
            'name': 'Beijing',
            'geoPt': [
                39.55,
                116.23
            ]
        }
    },
    {
        'name': 'Christmas Island',
        'aliases': [],
        'iso2': 'CX',
        'filter': 'christmas island',
        'capital': {
            'name': 'Flying Fish Cove',
            'geoPt': [
                -10.42,
                105.67
            ]
        }
    },
    {
        'name': 'Cocos (Keeling) Islands',
        'aliases': [],
        'iso2': 'CC',
        'filter': 'cocos (keeling) islands',
        'capital': {
            'name': 'West Island',
            'geoPt': [
                -12.17,
                96.82
            ]
        }
    },
    {
        'name': 'Colombia',
        'aliases': [],
        'iso2': 'CO',
        'filter': 'colombia',
        'capital': {
            'name': 'Bogota',
            'geoPt': [
                4.36,
                -74.05
            ]
        }
    },
    {
        'name': 'Comoros',
        'aliases': [],
        'iso2': 'KM',
        'filter': 'comoros',
        'capital': {
            'name': 'Moroni',
            'geoPt': [
                -11.42,
                43.14
            ]
        }
    },
    {
        'name': 'Republic of the Congo',
        'aliases': [],
        'iso2': 'CG',
        'filter': 'republic of the congo',
        'capital': {
            'name': 'Brazzaville',
            'geoPt': [
                -4.27,
                15.24
            ]
        }
    },
    {
        'name': 'Cook Islands',
        'aliases': [],
        'iso2': 'CK',
        'filter': 'cook islands',
        'capital': {
            'name': 'Avarua',
            'geoPt': [
                -21.12,
                -159.46
            ]
        }
    },
    {
        'name': 'Costa Rica',
        'aliases': [],
        'iso2': 'CR',
        'filter': 'costa rica',
        'capital': {
            'name': 'San Jose',
            'geoPt': [
                9.56,
                -84.05
            ]
        }
    },
    {
        'name': 'Croatia',
        'aliases': [],
        'iso2': 'HR',
        'filter': 'croatia',
        'capital': {
            'name': 'Zagreb',
            'geoPt': [
                45.48,
                16
            ]
        }
    },
    {
        'name': 'Cuba',
        'aliases': [],
        'iso2': 'CU',
        'filter': 'cuba',
        'capital': {
            'name': 'Havana',
            'geoPt': [
                23.07,
                -82.21
            ]
        }
    },
    {
        'name': 'Curaçao',
        'aliases': [
            'Curacao'
        ],
        'iso2': 'CW',
        'filter': 'curaçao curacao',
        'capital': {
            'name': 'Willemstad',
            'geoPt': [
                12.12,
                -68.88
            ]
        }
    },
    {
        'name': 'Cyprus',
        'aliases': [],
        'iso2': 'CY',
        'filter': 'cyprus',
        'capital': {
            'name': 'Nicosia',
            'geoPt': [
                35.1,
                33.22
            ]
        }
    },
    {
        'name': 'Czech Republic',
        'aliases': [],
        'iso2': 'CZ',
        'filter': 'czech republic',
        'capital': {
            'name': 'Prague',
            'geoPt': [
                50.05,
                14.28
            ]
        }
    },
    {
        'name': 'Côte d\'Ivoire',
        'aliases': [
            'Cote d\'Ivoire',
            'Ivory Coast'
        ],
        'iso2': 'CI',
        'filter': 'côte d\'ivoire cote d\'ivoire ivory coast',
        'capital': {
            'name': 'Yamoussoukro',
            'geoPt': [
                6.82,
                -5.27
            ]
        }
    },
    {
        'name': 'Democratic Republic of the Congo',
        'aliases': [],
        'iso2': 'CD',
        'filter': 'democratic republic of the congo',
        'capital': {
            'name': 'Kinshasa',
            'geoPt': [
                -4.19,
                15.18
            ]
        }
    },
    {
        'name': 'Denmark',
        'aliases': [],
        'iso2': 'DK',
        'filter': 'denmark',
        'capital': {
            'name': 'Copenhagen',
            'geoPt': [
                55.4,
                12.35
            ]
        }
    },
    {
        'name': 'Djibouti',
        'aliases': [],
        'iso2': 'DJ',
        'filter': 'djibouti',
        'capital': {
            'name': 'Djibouti',
            'geoPt': [
                11.35,
                43.09
            ]
        }
    },
    {
        'name': 'Dominica',
        'aliases': [],
        'iso2': 'DM',
        'filter': 'dominica',
        'capital': {
            'name': 'Roseau',
            'geoPt': [
                15.18,
                -61.24
            ]
        }
    },
    {
        'name': 'Dominican Republic',
        'aliases': [],
        'iso2': 'DO',
        'filter': 'dominican republic',
        'capital': {
            'name': 'Santo Domingo',
            'geoPt': [
                18.28,
                -69.54
            ]
        }
    },
    {
        'name': 'Ecuador',
        'aliases': [],
        'iso2': 'EC',
        'filter': 'ecuador',
        'capital': {
            'name': 'Quito',
            'geoPt': [
                -0.13,
                -78.3
            ]
        }
    },
    {
        'name': 'Egypt',
        'aliases': [],
        'iso2': 'EG',
        'filter': 'egypt',
        'capital': {
            'name': 'Cairo',
            'geoPt': [
                30.03,
                31.15
            ]
        }
    },
    {
        'name': 'El Salvador',
        'aliases': [],
        'iso2': 'SV',
        'filter': 'el salvador',
        'capital': {
            'name': 'San Salvador',
            'geoPt': [
                13.42,
                -89.12
            ]
        }
    },
    {
        'name': 'England',
        'aliases': [],
        'iso2': 'GB-ENG',
        'filter': 'england',
        'capital': {
            'name': 'London',
            'geoPt': [
                51.50,
                -0.12
            ]
        }
    },
    {
        'name': 'Equatorial Guinea',
        'aliases': [],
        'iso2': 'GQ',
        'filter': 'equatorial guinea',
        'capital': {
            'name': 'Malabo',
            'geoPt': [
                3.45,
                8.47
            ]
        }
    },
    {
        'name': 'Eritrea',
        'aliases': [],
        'iso2': 'ER',
        'filter': 'eritrea',
        'capital': {
            'name': 'Asmara',
            'geoPt': [
                15.2,
                38.56
            ]
        }
    },
    {
        'name': 'Estonia',
        'aliases': [],
        'iso2': 'EE',
        'filter': 'estonia',
        'capital': {
            'name': 'Tallinn',
            'geoPt': [
                59.26,
                24.43
            ]
        }
    },
    {
        'name': 'Eswatini',
        'aliases': [],
        'iso2': 'SZ',
        'filter': 'eswatini',
        'capital': {
            'name': 'Mbabane',
            'geoPt': [
                -26.18,
                31.06
            ]
        }
    },
    {
        'name': 'Ethiopia',
        'aliases': [],
        'iso2': 'ET',
        'filter': 'ethiopia',
        'capital': {
            'name': 'Addis Ababa',
            'geoPt': [
                9.02,
                38.42
            ]
        }
    },
    {
        'name': 'Falkland Islands',
        'aliases': [],
        'iso2': 'FK',
        'filter': 'falkland islands',
        'capital': {
            'name': 'Stanley',
            'geoPt': [
                -51.42,
                -57.51
            ]
        }
    },
    {
        'name': 'Faroe Islands',
        'aliases': [],
        'iso2': 'FO',
        'filter': 'faroe islands',
        'capital': {
            'name': 'Torshavn',
            'geoPt': [
                62.01,
                -6.46
            ]
        }
    },
    {
        'name': 'Federated States of Micronesia',
        'aliases': [],
        'iso2': 'FM',
        'filter': 'federated states of micronesia',
        'capital': {
            'name': 'Palikir',
            'geoPt': [
                6.55,
                158.09
            ]
        }
    },
    {
        'name': 'Fiji',
        'aliases': [],
        'iso2': 'FJ',
        'filter': 'fiji',
        'capital': {
            'name': 'Suva',
            'geoPt': [
                -18.08,
                178.25
            ]
        }
    },
    {
        'name': 'Finland',
        'aliases': [],
        'iso2': 'FI',
        'filter': 'finland',
        'capital': {
            'name': 'Helsinki',
            'geoPt': [
                60.1,
                24.56
            ]
        }
    },
    {
        'name': 'France',
        'aliases': [],
        'iso2': 'FR',
        'filter': 'france',
        'capital': {
            'name': 'Paris',
            'geoPt': [
                48.52,
                2.2
            ]
        }
    },
    {
        'name': 'French Guiana',
        'aliases': [],
        'iso2': 'GF',
        'filter': 'french guiana',
        'capital': {
            'name': 'Cayenne',
            'geoPt': [
                4.93,
                -52.32
            ]
        }
    },
    {
        'name': 'French Polynesia',
        'aliases': [],
        'iso2': 'PF',
        'filter': 'french polynesia',
        'capital': {
            'name': 'Papeete',
            'geoPt': [
                -17.53,
                -149.56
            ]
        }
    },
    {
        'name': 'French Southern Territories',
        'aliases': [],
        'iso2': 'TF',
        'filter': 'french southern territories',
        'capital': {
            'name': 'Port-aux-Francais',
            'geoPt': [
                -49.35,
                70.21
            ]
        }
    },
    {
        'name': 'Gabon',
        'aliases': [],
        'iso2': 'GA',
        'filter': 'gabon',
        'capital': {
            'name': 'Libreville',
            'geoPt': [
                0.23,
                9.27
            ]
        }
    },
    {
        'name': 'Georgia',
        'aliases': [],
        'iso2': 'GE',
        'filter': 'georgia',
        'capital': {
            'name': 'Tbilisi',
            'geoPt': [
                41.43,
                44.47
            ]
        }
    },
    {
        'name': 'Germany',
        'aliases': [],
        'iso2': 'DE',
        'filter': 'germany',
        'capital': {
            'name': 'Berlin',
            'geoPt': [
                52.31,
                13.24
            ]
        }
    },
    {
        'name': 'Ghana',
        'aliases': [],
        'iso2': 'GH',
        'filter': 'ghana',
        'capital': {
            'name': 'Accra',
            'geoPt': [
                5.33,
                -0.13
            ]
        }
    },
    {
        'name': 'Gibraltar',
        'aliases': [],
        'iso2': 'GI',
        'filter': 'gibraltar',
        'capital': {
            'name': 'Gibraltar',
            'geoPt': [
                36.08,
                -5.21
            ]
        }
    },
    {
        'name': 'Greece',
        'aliases': [],
        'iso2': 'GR',
        'filter': 'greece',
        'capital': {
            'name': 'Athens',
            'geoPt': [
                37.59,
                23.44
            ]
        }
    },
    {
        'name': 'Greenland',
        'aliases': [],
        'iso2': 'GL',
        'filter': 'greenland',
        'capital': {
            'name': 'Nuuk',
            'geoPt': [
                64.11,
                -51.45
            ]
        }
    },
    {
        'name': 'Grenada',
        'aliases': [],
        'iso2': 'GD',
        'filter': 'grenada',
        'capital': {
            'name': 'St. George\'s',
            'geoPt': [
                12.03,
                -61.45
            ]
        }
    },
    {
        'name': 'Guadeloupe',
        'aliases': [],
        'iso2': 'GP',
        'filter': 'guadeloupe',
        'capital': {
            'name': 'Basse-Terre',
            'geoPt': [
                16.12,
                -61.65
            ]
        }
    },
    {
        'name': 'Guam',
        'aliases': [],
        'iso2': 'GU',
        'filter': 'guam',
        'capital': {
            'name': 'Hagatna',
            'geoPt': [
                13.47,
                144.74
            ]
        }
    },
    {
        'name': 'Guatemala',
        'aliases': [],
        'iso2': 'GT',
        'filter': 'guatemala',
        'capital': {
            'name': 'Guatemala City',
            'geoPt': [
                14.37,
                -90.31
            ]
        }
    },
    {
        'name': 'Guernsey',
        'aliases': [],
        'iso2': 'GG',
        'filter': 'guernsey',
        'capital': {
            'name': 'St Peter Port',
            'geoPt': [
                49.27,
                -2.32
            ]
        }
    },
    {
        'name': 'Guinea',
        'aliases': [],
        'iso2': 'GN',
        'filter': 'guinea',
        'capital': {
            'name': 'Conakry',
            'geoPt': [
                9.33,
                -13.42
            ]
        }
    },
    {
        'name': 'Guinea-Bissau',
        'aliases': [],
        'iso2': 'GW',
        'filter': 'guinea-bissau',
        'capital': {
            'name': 'Bissau',
            'geoPt': [
                11.51,
                -15.35
            ]
        }
    },
    {
        'name': 'Guyana',
        'aliases': [],
        'iso2': 'GY',
        'filter': 'guyana',
        'capital': {
            'name': 'Georgetown',
            'geoPt': [
                6.48,
                -58.1
            ]
        }
    },
    {
        'name': 'Haiti',
        'aliases': [],
        'iso2': 'HT',
        'filter': 'haiti',
        'capital': {
            'name': 'Port-au-Prince',
            'geoPt': [
                18.32,
                -72.2
            ]
        }
    },
    {
        'name': 'Honduras',
        'aliases': [],
        'iso2': 'HN',
        'filter': 'honduras',
        'capital': {
            'name': 'Tegucigalpa',
            'geoPt': [
                14.06,
                -87.13
            ]
        }
    },
    {
        'name': 'Hong Kong',
        'aliases': [],
        'iso2': 'HK',
        'filter': 'hong kong',
        'capital': {
            'name': 'Hong Kong',
            'geoPt': [
                22.31,
                114.16
            ]
        }
    },
    {
        'name': 'Hungary',
        'aliases': [],
        'iso2': 'HU',
        'filter': 'hungary',
        'capital': {
            'name': 'Budapest',
            'geoPt': [
                47.3,
                19.05
            ]
        }
    },
    {
        'name': 'Iceland',
        'aliases': [],
        'iso2': 'IS',
        'filter': 'iceland',
        'capital': {
            'name': 'Reykjavik',
            'geoPt': [
                64.09,
                -21.57
            ]
        }
    },
    {
        'name': 'India',
        'aliases': [],
        'iso2': 'IN',
        'filter': 'india',
        'capital': {
            'name': 'New Delhi',
            'geoPt': [
                28.36,
                77.12
            ]
        }
    },
    {
        'name': 'Indonesia',
        'aliases': [],
        'iso2': 'ID',
        'filter': 'indonesia',
        'capital': {
            'name': 'Jakarta',
            'geoPt': [
                -6.1,
                106.49
            ]
        }
    },
    {
        'name': 'Iran',
        'aliases': [],
        'iso2': 'IR',
        'filter': 'iran',
        'capital': {
            'name': 'Tehran',
            'geoPt': [
                35.4,
                51.25
            ]
        }
    },
    {
        'name': 'Iraq',
        'aliases': [],
        'iso2': 'IQ',
        'filter': 'iraq',
        'capital': {
            'name': 'Baghdad',
            'geoPt': [
                33.2,
                44.23
            ]
        }
    },
    {
        'name': 'Ireland',
        'aliases': [],
        'iso2': 'IE',
        'filter': 'ireland',
        'capital': {
            'name': 'Dublin',
            'geoPt': [
                53.19,
                -6.14
            ]
        }
    },
    {
        'name': 'Isle of Man',
        'aliases': [],
        'iso2': 'IM',
        'filter': 'isle of man',
        'capital': {
            'name': 'Douglas',
            'geoPt': [
                54.15,
                -4.48
            ]
        }
    },
    {
        'name': 'Israel',
        'aliases': [],
        'iso2': 'IL',
        'filter': 'israel',
        'capital': {
            'name': 'Jerusalem',
            'geoPt': [
                31.46,
                35.14
            ]
        }
    },
    {
        'name': 'Italy',
        'aliases': [],
        'iso2': 'IT',
        'filter': 'italy',
        'capital': {
            'name': 'Rome',
            'geoPt': [
                41.54,
                12.29
            ]
        }
    },
    {
        'name': 'Jamaica',
        'aliases': [],
        'iso2': 'JM',
        'filter': 'jamaica',
        'capital': {
            'name': 'Kingston',
            'geoPt': [
                18,
                -76.48
            ]
        }
    },
    {
        'name': 'Japan',
        'aliases': [],
        'iso2': 'JP',
        'filter': 'japan',
        'capital': {
            'name': 'Tokyo',
            'geoPt': [
                35.41,
                139.45
            ]
        }
    },
    {
        'name': 'Jersey',
        'aliases': [],
        'iso2': 'JE',
        'filter': 'jersey',
        'capital': {
            'name': 'Saint Helier',
            'geoPt': [
                49.11,
                -2.06
            ]
        }
    },
    {
        'name': 'Jordan',
        'aliases': [],
        'iso2': 'JO',
        'filter': 'jordan',
        'capital': {
            'name': 'Amman',
            'geoPt': [
                31.57,
                35.56
            ]
        }
    },
    {
        'name': 'Kazakhstan',
        'aliases': [],
        'iso2': 'KZ',
        'filter': 'kazakhstan',
        'capital': {
            'name': 'Nur-Sultan',
            'geoPt': [
                51.1,
                71.25
            ]
        }
    },
    {
        'name': 'Kenya',
        'aliases': [],
        'iso2': 'KE',
        'filter': 'kenya',
        'capital': {
            'name': 'Nairobi',
            'geoPt': [
                -1.17,
                36.49
            ]
        }
    },
    {
        'name': 'Kiribati',
        'aliases': [],
        'iso2': 'KI',
        'filter': 'kiribati',
        'capital': {
            'name': 'Tarawa',
            'geoPt': [
                1.19,
                172.58
            ]
        }
    },
    {
        'name': 'Kosovo',
        'aliases': [],
        'iso2': 'XK',
        'filter': 'kosovo',
        'capital': {
            'name': 'Pristina',
            'geoPt': [
                42.65,
                21.16
            ]
        }
    },
    {
        'name': 'Kuwait',
        'aliases': [],
        'iso2': 'KW',
        'filter': 'kuwait',
        'capital': {
            'name': 'Kuwait City',
            'geoPt': [
                29.22,
                47.58
            ]
        }
    },
    {
        'name': 'Kyrgyzstan',
        'aliases': [],
        'iso2': 'KG',
        'filter': 'kyrgyzstan',
        'capital': {
            'name': 'Bishkek',
            'geoPt': [
                42.52,
                74.36
            ]
        }
    },
    {
        'name': 'Laos',
        'aliases': [],
        'iso2': 'LA',
        'filter': 'laos',
        'capital': {
            'name': 'Vientiane',
            'geoPt': [
                17.58,
                102.36
            ]
        }
    },
    {
        'name': 'Latvia',
        'aliases': [],
        'iso2': 'LV',
        'filter': 'latvia',
        'capital': {
            'name': 'Riga',
            'geoPt': [
                56.57,
                24.06
            ]
        }
    },
    {
        'name': 'Lebanon',
        'aliases': [],
        'iso2': 'LB',
        'filter': 'lebanon',
        'capital': {
            'name': 'Beirut',
            'geoPt': [
                33.52,
                35.3
            ]
        }
    },
    {
        'name': 'Lesotho',
        'aliases': [],
        'iso2': 'LS',
        'filter': 'lesotho',
        'capital': {
            'name': 'Maseru',
            'geoPt': [
                -29.19,
                27.29
            ]
        }
    },
    {
        'name': 'Liberia',
        'aliases': [],
        'iso2': 'LR',
        'filter': 'liberia',
        'capital': {
            'name': 'Monrovia',
            'geoPt': [
                6.18,
                -10.48
            ]
        }
    },
    {
        'name': 'Libya',
        'aliases': [],
        'iso2': 'LY',
        'filter': 'libya',
        'capital': {
            'name': 'Tripoli',
            'geoPt': [
                32.53,
                13.1
            ]
        }
    },
    {
        'name': 'Liechtenstein',
        'aliases': [],
        'iso2': 'LI',
        'filter': 'liechtenstein',
        'capital': {
            'name': 'Vaduz',
            'geoPt': [
                47.08,
                9.31
            ]
        }
    },
    {
        'name': 'Lithuania',
        'aliases': [],
        'iso2': 'LT',
        'filter': 'lithuania',
        'capital': {
            'name': 'Vilnius',
            'geoPt': [
                54.41,
                25.19
            ]
        }
    },
    {
        'name': 'Luxembourg',
        'aliases': [],
        'iso2': 'LU',
        'filter': 'luxembourg',
        'capital': {
            'name': 'Luxembourg',
            'geoPt': [
                49.36,
                6.07
            ]
        }
    },
    {
        'name': 'Macao',
        'aliases': [],
        'iso2': 'MO',
        'filter': 'macao',
        'capital': {
            'name': 'Macao',
            'geoPt': [
                22.16,
                113.54
            ]
        }
    },
    {
        'name': 'Madagascar',
        'aliases': [],
        'iso2': 'MG',
        'filter': 'madagascar',
        'capital': {
            'name': 'Antananarivo',
            'geoPt': [
                -18.55,
                47.31
            ]
        }
    },
    {
        'name': 'Malawi',
        'aliases': [],
        'iso2': 'MW',
        'filter': 'malawi',
        'capital': {
            'name': 'Lilongwe',
            'geoPt': [
                -13.59,
                33.47
            ]
        }
    },
    {
        'name': 'Malaysia',
        'aliases': [],
        'iso2': 'MY',
        'filter': 'malaysia',
        'capital': {
            'name': 'Kuala Lumpur',
            'geoPt': [
                3.1,
                101.42
            ]
        }
    },
    {
        'name': 'Maldives',
        'aliases': [],
        'iso2': 'MV',
        'filter': 'maldives',
        'capital': {
            'name': 'Male',
            'geoPt': [
                4.1,
                73.3
            ]
        }
    },
    {
        'name': 'Mali',
        'aliases': [],
        'iso2': 'ML',
        'filter': 'mali',
        'capital': {
            'name': 'Bamako',
            'geoPt': [
                12.39,
                -8
            ]
        }
    },
    {
        'name': 'Malta',
        'aliases': [],
        'iso2': 'MT',
        'filter': 'malta',
        'capital': {
            'name': 'Valletta',
            'geoPt': [
                35.53,
                14.3
            ]
        }
    },
    {
        'name': 'Marshall Islands',
        'aliases': [],
        'iso2': 'MH',
        'filter': 'marshall islands',
        'capital': {
            'name': 'Majuro',
            'geoPt': [
                7.06,
                171.23
            ]
        }
    },
    {
        'name': 'Martinique',
        'aliases': [],
        'iso2': 'MQ',
        'filter': 'martinique',
        'capital': {
            'name': 'Fort-de-France',
            'geoPt': [
                14.60,
                -61.06
            ]
        }
    },
    {
        'name': 'Mauritania',
        'aliases': [],
        'iso2': 'MR',
        'filter': 'mauritania',
        'capital': {
            'name': 'Nouakchott',
            'geoPt': [
                18.07,
                -16.02
            ]
        }
    },
    {
        'name': 'Mauritius',
        'aliases': [],
        'iso2': 'MU',
        'filter': 'mauritius',
        'capital': {
            'name': 'Port Louis',
            'geoPt': [
                -20.09,
                57.29
            ]
        }
    },
    {
        'name': 'Mayotte',
        'aliases': [],
        'iso2': 'YT',
        'filter': 'mayotte',
        'capital': {
            'name': 'Mamoudzou',
            'geoPt': [
                -12.78,
                45.23
            ]
        }
    },
    {
        'name': 'Mexico',
        'aliases': [],
        'iso2': 'MX',
        'filter': 'mexico',
        'capital': {
            'name': 'Mexico City',
            'geoPt': [
                19.26,
                -99.08
            ]
        }
    },
    {
        'name': 'Moldova',
        'aliases': [],
        'iso2': 'MD',
        'filter': 'moldova',
        'capital': {
            'name': 'Chisinau',
            'geoPt': [
                47,
                28.51
            ]
        }
    },
    {
        'name': 'Monaco',
        'aliases': [],
        'iso2': 'MC',
        'filter': 'monaco',
        'capital': {
            'name': 'Monaco',
            'geoPt': [
                43.44,
                7.25
            ]
        }
    },
    {
        'name': 'Mongolia',
        'aliases': [],
        'iso2': 'MN',
        'filter': 'mongolia',
        'capital': {
            'name': 'Ulaanbaatar',
            'geoPt': [
                47.55,
                106.55
            ]
        }
    },
    {
        'name': 'Montenegro',
        'aliases': [],
        'iso2': 'ME',
        'filter': 'montenegro',
        'capital': {
            'name': 'Podgorica',
            'geoPt': [
                42.26,
                19.16
            ]
        }
    },
    {
        'name': 'Montserrat',
        'aliases': [],
        'iso2': 'MS',
        'filter': 'montserrat',
        'capital': {
            'name': 'Plymouth',
            'geoPt': [
                16.70,
                -62.21
            ]
        }
    },
    {
        'name': 'Morocco',
        'aliases': [],
        'iso2': 'MA',
        'filter': 'morocco',
        'capital': {
            'name': 'Rabat',
            'geoPt': [
                34.01,
                -6.49
            ]
        }
    },
    {
        'name': 'Mozambique',
        'aliases': [],
        'iso2': 'MZ',
        'filter': 'mozambique',
        'capital': {
            'name': 'Maputo',
            'geoPt': [
                -25.57,
                32.35
            ]
        }
    },
    {
        'name': 'Myanmar',
        'aliases': [
            'Burma'
        ],
        'iso2': 'MM',
        'filter': 'myanmar burma',
        'capital': {
            'name': 'Nay Pyi Taw',
            'geoPt': [
                16.48,
                96.09
            ]
        }
    },
    {
        'name': 'Namibia',
        'aliases': [],
        'iso2': 'NA',
        'filter': 'namibia',
        'capital': {
            'name': 'Windhoek',
            'geoPt': [
                -22.34,
                17.05
            ]
        }
    },
    {
        'name': 'Nauru',
        'aliases': [],
        'iso2': 'NR',
        'filter': 'nauru',
        'capital': {
            'name': 'Yaren',
            'geoPt': [
                -0.54,
                166.92
            ]
        }
    },
    {
        'name': 'Nepal',
        'aliases': [],
        'iso2': 'NP',
        'filter': 'nepal',
        'capital': {
            'name': 'Kathmandu',
            'geoPt': [
                27.43,
                85.19
            ]
        }
    },
    {
        'name': 'Netherlands',
        'aliases': [],
        'iso2': 'NL',
        'filter': 'netherlands',
        'capital': {
            'name': 'Amsterdam',
            'geoPt': [
                52.23,
                4.54
            ]
        }
    },
    {
        'name': 'New Caledonia',
        'aliases': [],
        'iso2': 'NC',
        'filter': 'new caledonia',
        'capital': {
            'name': 'Noumea',
            'geoPt': [
                -22.16,
                166.27
            ]
        }
    },
    {
        'name': 'New Zealand',
        'aliases': [],
        'iso2': 'NZ',
        'filter': 'new zealand',
        'capital': {
            'name': 'Wellington',
            'geoPt': [
                -41.28,
                174.51
            ]
        }
    },
    {
        'name': 'Nicaragua',
        'aliases': [],
        'iso2': 'NI',
        'filter': 'nicaragua',
        'capital': {
            'name': 'Managua',
            'geoPt': [
                12.09,
                -86.17
            ]
        }
    },
    {
        'name': 'Niger',
        'aliases': [],
        'iso2': 'NE',
        'filter': 'niger',
        'capital': {
            'name': 'Niamey',
            'geoPt': [
                13.31,
                2.07
            ]
        }
    },
    {
        'name': 'Nigeria',
        'aliases': [],
        'iso2': 'NG',
        'filter': 'nigeria',
        'capital': {
            'name': 'Abuja',
            'geoPt': [
                9.05,
                7.32
            ]
        }
    },
    {
        'name': 'Niue',
        'aliases': [],
        'iso2': 'NU',
        'filter': 'niue',
        'capital': {
            'name': 'Alofi',
            'geoPt': [
                -19.01,
                -169.55
            ]
        }
    },
    {
        'name': 'Norfolk Island',
        'aliases': [],
        'iso2': 'NF',
        'filter': 'norfolk island',
        'capital': {
            'name': 'Kingston',
            'geoPt': [
                -29.03,
                167.58
            ]
        }
    },
    {
        'name': 'North Korea',
        'aliases': [
            'Democratic Peoples Republic of Korea'
        ],
        'iso2': 'KP',
        'filter': 'north korea democratic peoples republic of korea',
        'capital': {
            'name': 'Pyongyang',
            'geoPt': [
                39.01,
                125.45
            ]
        }
    },
    {
        'name': 'North Macedonia',
        'aliases': [],
        'iso2': 'MK',
        'filter': 'north macedonia',
        'capital': {
            'name': 'Skopje',
            'geoPt': [
                42,
                21.26
            ]
        }
    },
    {
        'name': 'Northern Ireland',
        'aliases': [],
        'iso2': 'GB-NIR',
        'filter': 'northern ireland',
        'capital': {
            'name': 'Belfast',
            'geoPt': [
                54.59,
                -5.93
            ]
        }
    },
    {
        'name': 'Northern Mariana Islands',
        'aliases': [],
        'iso2': 'MP',
        'filter': 'northern mariana islands',
        'capital': {
            'name': 'Saipan',
            'geoPt': [
                15.12,
                145.72
            ]
        }
    },
    {
        'name': 'Norway',
        'aliases': [],
        'iso2': 'NO',
        'filter': 'norway',
        'capital': {
            'name': 'Oslo',
            'geoPt': [
                59.55,
                10.45
            ]
        }
    },
    {
        'name': 'Oman',
        'aliases': [],
        'iso2': 'OM',
        'filter': 'oman',
        'capital': {
            'name': 'Muscat',
            'geoPt': [
                23.37,
                58.35
            ]
        }
    },
    {
        'name': 'Pakistan',
        'aliases': [],
        'iso2': 'PK',
        'filter': 'pakistan',
        'capital': {
            'name': 'Islamabad',
            'geoPt': [
                33.42,
                73.1
            ]
        }
    },
    {
        'name': 'Palau',
        'aliases': [],
        'iso2': 'PW',
        'filter': 'palau',
        'capital': {
            'name': 'Melekeok',
            'geoPt': [
                7.29,
                134.38
            ]
        }
    },
    {
        'name': 'Palestine',
        'aliases': [],
        'iso2': 'PS',
        'filter': 'palestine',
        'capital': {
            'name': 'East Jerusalem',
            'geoPt': [
                31.46,
                35.14
            ]
        }
    },
    {
        'name': 'Panama',
        'aliases': [],
        'iso2': 'PA',
        'filter': 'panama',
        'capital': {
            'name': 'Panama City',
            'geoPt': [
                8.58,
                -79.32
            ]
        }
    },
    {
        'name': 'Papua New Guinea',
        'aliases': [],
        'iso2': 'PG',
        'filter': 'papua new guinea',
        'capital': {
            'name': 'Port Moresby',
            'geoPt': [
                -9.3,
                147.1
            ]
        }
    },
    {
        'name': 'Paraguay',
        'aliases': [],
        'iso2': 'PY',
        'filter': 'paraguay',
        'capital': {
            'name': 'Asuncion',
            'geoPt': [
                -25.16,
                -57.4
            ]
        }
    },
    {
        'name': 'Peru',
        'aliases': [],
        'iso2': 'PE',
        'filter': 'peru',
        'capital': {
            'name': 'Lima',
            'geoPt': [
                -12.03,
                -77.03
            ]
        }
    },
    {
        'name': 'Philippines',
        'aliases': [],
        'iso2': 'PH',
        'filter': 'philippines',
        'capital': {
            'name': 'Manila',
            'geoPt': [
                14.35,
                121
            ]
        }
    },
    {
        'name': 'Pitcairn Islands',
        'aliases': [],
        'iso2': 'PN',
        'filter': 'pitcairn islands',
        'capital': {
            'name': 'Adamstown',
            'geoPt': [
                -25.06,
                -130.10
            ]
        }
    },
    {
        'name': 'Poland',
        'aliases': [],
        'iso2': 'PL',
        'filter': 'poland',
        'capital': {
            'name': 'Warsaw',
            'geoPt': [
                52.15,
                21
            ]
        }
    },
    {
        'name': 'Portugal',
        'aliases': [],
        'iso2': 'PT',
        'filter': 'portugal',
        'capital': {
            'name': 'Lisbon',
            'geoPt': [
                38.43,
                -9.08
            ]
        }
    },
    {
        'name': 'Puerto Rico',
        'aliases': [],
        'iso2': 'PR',
        'filter': 'puerto rico',
        'capital': {
            'name': 'San Juan',
            'geoPt': [
                18.28,
                -66.07
            ]
        }
    },
    {
        'name': 'Qatar',
        'aliases': [],
        'iso2': 'QA',
        'filter': 'qatar',
        'capital': {
            'name': 'Doha',
            'geoPt': [
                25.17,
                51.32
            ]
        }
    },
    {
        'name': 'Romania',
        'aliases': [],
        'iso2': 'RO',
        'filter': 'romania',
        'capital': {
            'name': 'Bucharest',
            'geoPt': [
                44.26,
                26.06
            ]
        }
    },
    {
        'name': 'Russian Federation',
        'aliases': [
            'Russia'
        ],
        'iso2': 'RU',
        'filter': 'russian federation russia',
        'capital': {
            'name': 'Moscow',
            'geoPt': [
                55.45,
                37.35
            ]
        }
    },
    {
        'name': 'Rwanda',
        'aliases': [],
        'iso2': 'RW',
        'filter': 'rwanda',
        'capital': {
            'name': 'Kigali',
            'geoPt': [
                -1.57,
                30.04
            ]
        }
    },
    {
        'name': 'Réunion',
        'aliases': [
            'Reunion'
        ],
        'iso2': 'RE',
        'filter': 'réunion reunion',
        'capital': {
            'name': 'Saint-Denis',
            'geoPt': [
                -20.88,
                55.45
            ]
        }
    },
    {
        'name': 'Saba',
        'aliases': [],
        'iso2': 'BQ-SA',
        'filter': 'saba',
        'capital': {
            'name': 'The Bottom',
            'geoPt': [
                17.62,
                -63.24
            ]
        }
    },
    {
        'name': 'Saint Barthélemy',
        'aliases': [
            'Saint Barthelemy'
        ],
        'iso2': 'BL',
        'filter': 'saint barthélemy saint barthelemy',
        'capital': {
            'name': 'Gustavia',
            'geoPt': [
                17/89,
                -62.85
            ]
        }
    },
    {
        'name': 'Saint Helena, Ascension, and Tristan da Cunha',
        'aliases': [],
        'iso2': 'SH',
        'filter': 'saint helena ascension and tristan da cunha',
        'capital': {
            'name': 'Jamestown',
            'geoPt': [
                -15.92,
                -5.71
            ]
        }
    },
    {
        'name': 'Saint Kitts and Nevis',
        'aliases': [],
        'iso2': 'KN',
        'filter': 'saint kitts and nevis',
        'capital': {
            'name': 'Basseterre',
            'geoPt': [
                17.18,
                -62.43
            ]
        }
    },
    {
        'name': 'Saint Lucia',
        'aliases': [],
        'iso2': 'LC',
        'filter': 'saint lucia',
        'capital': {
            'name': 'Castries',
            'geoPt': [
                14.01,
                -61
            ]
        }
    },
    {
        'name': 'Saint Martin',
        'aliases': [],
        'iso2': 'MF',
        'filter': 'saint martin',
        'capital': {
            'name': 'Marigot',
            'geoPt': [
                18.06,
                -63.08
            ]
        }
    },
    {
        'name': 'Saint Pierre and Miquelon',
        'aliases': [],
        'iso2': 'PM',
        'filter': 'saint pierre and miquelon',
        'capital': {
            'name': 'Saint-Pierre',
            'geoPt': [
                46.77,
                -56.18
            ]
        }
    },
    {
        'name': 'Saint Vincent and the Grenadines',
        'aliases': [],
        'iso2': 'VC',
        'filter': 'saint vincent and the grenadines',
        'capital': {
            'name': 'Kingstown',
            'geoPt': [
                13.09,
                -61.14
            ]
        }
    },
    {
        'name': 'Samoa',
        'aliases': [],
        'iso2': 'WS',
        'filter': 'samoa',
        'capital': {
            'name': 'Apia',
            'geoPt': [
                -13.5,
                -171.44
            ]
        }
    },
    {
        'name': 'San Marino',
        'aliases': [],
        'iso2': 'SM',
        'filter': 'san marino',
        'capital': {
            'name': 'San Marino',
            'geoPt': [
                43.56,
                12.25
            ]
        }
    },
    {
        'name': 'Saudi Arabia',
        'aliases': [],
        'iso2': 'SA',
        'filter': 'saudi arabia',
        'capital': {
            'name': 'Riyadh',
            'geoPt': [
                24.38,
                46.43
            ]
        }
    },
    {
        'name': 'Scotland',
        'aliases': [],
        'iso2': 'GB-SCT',
        'filter': 'scotland',
        'capital': {
            'name': 'Edinburgh',
            'geoPt': [
                55.94,
                -3.18
            ]
        }
    },
    {
        'name': 'Senegal',
        'aliases': [],
        'iso2': 'SN',
        'filter': 'senegal',
        'capital': {
            'name': 'Dakar',
            'geoPt': [
                14.4,
                -17.26
            ]
        }
    },
    {
        'name': 'Serbia',
        'aliases': [],
        'iso2': 'RS',
        'filter': 'serbia',
        'capital': {
            'name': 'Belgrade',
            'geoPt': [
                44.5,
                20.3
            ]
        }
    },
    {
        'name': 'Seychelles',
        'aliases': [],
        'iso2': 'SC',
        'filter': 'seychelles',
        'capital': {
            'name': 'Victoria',
            'geoPt': [
                -4.38,
                55.27
            ]
        }
    },
    {
        'name': 'Sierra Leone',
        'aliases': [],
        'iso2': 'SL',
        'filter': 'sierra leone',
        'capital': {
            'name': 'Freetown',
            'geoPt': [
                8.3,
                -13.15
            ]
        }
    },
    {
        'name': 'Singapore',
        'aliases': [],
        'iso2': 'SG',
        'filter': 'singapore',
        'capital': {
            'name': 'Singapore',
            'geoPt': [
                1.17,
                103.51
            ]
        }
    },
    {
        'name': 'Sint Eustatius',
        'aliases': [],
        'iso2': 'BQ-SE',
        'filter': 'sint eustatius',
        'capital': {
            'name': 'Oranjestad',
            'geoPt': [
                17.48,
                -62.97
            ]
        }
    },
    {
        'name': 'Sint Maarten',
        'aliases': [],
        'iso2': 'SX',
        'filter': 'sint maarten',
        'capital': {
            'name': 'Philipsburg',
            'geoPt': [
                18.02,
                -63.04
            ]
        }
    },
    {
        'name': 'Slovakia',
        'aliases': [],
        'iso2': 'SK',
        'filter': 'slovakia',
        'capital': {
            'name': 'Bratislava',
            'geoPt': [
                48.09,
                17.07
            ]
        }
    },
    {
        'name': 'Slovenia',
        'aliases': [],
        'iso2': 'SI',
        'filter': 'slovenia',
        'capital': {
            'name': 'Ljubljana',
            'geoPt': [
                46.03,
                14.31
            ]
        }
    },
    {
        'name': 'Solomon Islands',
        'aliases': [],
        'iso2': 'SB',
        'filter': 'solomon islands',
        'capital': {
            'name': 'Honiara',
            'geoPt': [
                -9.26,
                159.57
            ]
        }
    },
    {
        'name': 'Somalia',
        'aliases': [],
        'iso2': 'SO',
        'filter': 'somalia',
        'capital': {
            'name': 'Mogadishu',
            'geoPt': [
                2.04,
                45.22
            ]
        }
    },
    {
        'name': 'South Africa',
        'aliases': [],
        'iso2': 'ZA',
        'filter': 'south africa',
        'capital': {
            'name': 'Pretoria',
            'geoPt': [
                -25.42,
                28.13
            ]
        }
    },
    {
        'name': 'South Georgia and the South Sandwich Islands',
        'aliases': [],
        'iso2': 'GS',
        'filter': 'south georgia and the south sandwich islands',
        'capital': {
            'name': 'King Edward Point',
            'geoPt': [
                -54.28,
                -36.49
            ]
        }
    },
    {
        'name': 'South Korea',
        'aliases': [
            'Republic of Korea'
        ],
        'iso2': 'KR',
        'filter': 'south korea republic of korea',
        'capital': {
            'name': 'Seoul',
            'geoPt': [
                37.33,
                126.59
            ]
        }
    },
    {
        'name': 'South Sudan',
        'aliases': [],
        'iso2': 'SS',
        'filter': 'south sudan',
        'capital': {
            'name': 'Juba',
            'geoPt': [
                4.84,
                31.57
            ]
        }
    },
    {
        'name': 'Spain',
        'aliases': [],
        'iso2': 'ES',
        'filter': 'spain',
        'capital': {
            'name': 'Madrid',
            'geoPt': [
                40.24,
                -3.41
            ]
        }
    },
    {
        'name': 'Sri Lanka',
        'aliases': [],
        'iso2': 'LK',
        'filter': 'sri lanka',
        'capital': {
            'name': 'Colombo',
            'geoPt': [
                6.56,
                79.51
            ]
        }
    },
    {
        'name': 'Sudan',
        'aliases': [],
        'iso2': 'SD',
        'filter': 'sudan',
        'capital': {
            'name': 'Khartoum',
            'geoPt': [
                15.36,
                32.32
            ]
        }
    },
    {
        'name': 'Suriname',
        'aliases': [],
        'iso2': 'SR',
        'filter': 'suriname',
        'capital': {
            'name': 'Paramaribo',
            'geoPt': [
                5.5,
                -55.1
            ]
        }
    },
    {
        'name': 'Svalbard and Jan Mayen',
        'aliases': [],
        'iso2': 'SJ',
        'filter': 'svalbard and jan mayen',
        'capital': {
            'name': 'Longyearbyen',
            'geoPt': [
                78.22,
                15.63
            ]
        }
    },
    {
        'name': 'Sweden',
        'aliases': [],
        'iso2': 'SE',
        'filter': 'sweden',
        'capital': {
            'name': 'Stockholm',
            'geoPt': [
                59.2,
                18.03
            ]
        }
    },
    {
        'name': 'Switzerland',
        'aliases': [],
        'iso2': 'CH',
        'filter': 'switzerland',
        'capital': {
            'name': 'Bern',
            'geoPt': [
                46.57,
                7.26
            ]
        }
    },
    {
        'name': 'Syria',
        'aliases': [],
        'iso2': 'SY',
        'filter': 'syria',
        'capital': {
            'name': 'Damascus',
            'geoPt': [
                33.3,
                36.18
            ]
        }
    },
    {
        'name': 'São Tomé and Príncipe',
        'aliases': [
            'Sao Tome and Principe'
        ],
        'iso2': 'ST',
        'filter': 'são tomé and príncipe sao tome and principe',
        'capital': {
            'name': 'Sao Tome',
            'geoPt': [
                0.12,
                6.39
            ]
        }
    },
    {
        'name': 'Taiwan',
        'aliases': [],
        'iso2': 'TW',
        'filter': 'taiwan',
        'capital': {
            'name': 'Taipei',
            'geoPt': [
                25.03,
                121.3
            ]
        }
    },
    {
        'name': 'Tajikistan',
        'aliases': [],
        'iso2': 'TJ',
        'filter': 'tajikistan',
        'capital': {
            'name': 'Dushanbe',
            'geoPt': [
                38.35,
                68.48
            ]
        }
    },
    {
        'name': 'Tanzania',
        'aliases': [],
        'iso2': 'TZ',
        'filter': 'tanzania',
        'capital': {
            'name': 'Dodoma',
            'geoPt': [
                -6.48,
                39.17
            ]
        }
    },
    {
        'name': 'Thailand',
        'aliases': [],
        'iso2': 'TH',
        'filter': 'thailand',
        'capital': {
            'name': 'Bangkok',
            'geoPt': [
                13.45,
                100.31
            ]
        }
    },
    {
        'name': 'The Gambia',
        'aliases': [],
        'iso2': 'GM',
        'filter': 'the gambia',
        'capital': {
            'name': 'Banjul',
            'geoPt': [
                13.27,
                -16.34
            ]
        }
    },
    {
        'name': 'Timor-Leste',
        'aliases': [
            'East Timor'
        ],
        'iso2': 'TL',
        'filter': 'timor-leste east timor',
        'capital': {
            'name': 'Dili',
            'geoPt': [
                -8.35,
                125.36
            ]
        }
    },
    {
        'name': 'Togo',
        'aliases': [],
        'iso2': 'TG',
        'filter': 'togo',
        'capital': {
            'name': 'Lome',
            'geoPt': [
                6.08,
                1.13
            ]
        }
    },
    {
        'name': 'Tokelau',
        'aliases': [],
        'iso2': 'TK',
        'filter': 'tokelau',
        'capital': {
            'name': 'Nukunonu',
            'geoPt': [
                -9.16,
                -171.82
            ]
        }
    },
    {
        'name': 'Tonga',
        'aliases': [],
        'iso2': 'TO',
        'filter': 'tonga',
        'capital': {
            'name': 'Nuku\'alofa',
            'geoPt': [
                -21.08,
                -175.12
            ]
        }
    },
    {
        'name': 'Trinidad and Tobago',
        'aliases': [],
        'iso2': 'TT',
        'filter': 'trinidad and tobago',
        'capital': {
            'name': 'Port of Spain',
            'geoPt': [
                10.39,
                -61.31
            ]
        }
    },
    {
        'name': 'Tunisia',
        'aliases': [],
        'iso2': 'TN',
        'filter': 'tunisia',
        'capital': {
            'name': 'Tunis',
            'geoPt': [
                36.48,
                10.11
            ]
        }
    },
    {
        'name': 'Turkmenistan',
        'aliases': [],
        'iso2': 'TM',
        'filter': 'turkmenistan',
        'capital': {
            'name': 'Ashgabat',
            'geoPt': [
                37.57,
                58.23
            ]
        }
    },
    {
        'name': 'Turks and Caicos Islands',
        'aliases': [],
        'iso2': 'TC',
        'filter': 'turks and caicos islands',
        'capital': {
            'name': 'Cockburn Town',
            'geoPt': [
                21.28,
                -71.08
            ]
        }
    },
    {
        'name': 'Tuvalu',
        'aliases': [],
        'iso2': 'TV',
        'filter': 'tuvalu',
        'capital': {
            'name': 'Funafuti',
            'geoPt': [
                -8.3,
                179.12
            ]
        }
    },
    {
        'name': 'Türkiye',
        'aliases': [
            'Turkey'
        ],
        'iso2': 'TR',
        'filter': 'türkiye turkey',
        'capital': {
            'name': 'Ankara',
            'geoPt': [
                39.56,
                32.52
            ]
        }
    },
    {
        'name': 'U.S. Virgin Islands',
        'aliases': [],
        'iso2': 'VI',
        'filter': 'u.s. virgin islands',
        'capital': {
            'name': 'Charlotte Amalie',
            'geoPt': [
                18.34,
                -64.93
            ]
        }
    },
    {
        'name': 'Uganda',
        'aliases': [],
        'iso2': 'UG',
        'filter': 'uganda',
        'capital': {
            'name': 'Kampala',
            'geoPt': [
                0.19,
                32.25
            ]
        }
    },
    {
        'name': 'Ukraine',
        'aliases': [],
        'iso2': 'UA',
        'filter': 'ukraine',
        'capital': {
            'name': 'Kyiv',
            'geoPt': [
                50.45,
                30.52
            ]
        }
    },
    {
        'name': 'United Arab Emirates',
        'aliases': [],
        'iso2': 'AE',
        'filter': 'united arab emirates',
        'capital': {
            'name': 'Abu Dhabi',
            'geoPt': [
                24.28,
                54.22
            ]
        }
    },
    {
        'name': 'United Kingdom',
        'aliases': [
            'Great Britain'
        ],
        'iso2': 'GB',
        'filter': 'united kingdom great britain',
        'capital': {
            'name': 'London',
            'geoPt': [
                51.3,
                -0.1
            ]
        }
    },
    {
        'name': 'United States of America',
        'aliases': [],
        'iso2': 'US',
        'filter': 'united states of america',
        'capital': {
            'name': 'Washington D.C.',
            'geoPt': [
                38.53,
                -77.02
            ]
        }
    },
    {
        'name': 'Uruguay',
        'aliases': [],
        'iso2': 'UY',
        'filter': 'uruguay',
        'capital': {
            'name': 'Montevideo',
            'geoPt': [
                -34.53,
                -56.11
            ]
        }
    },
    {
        'name': 'Uzbekistan',
        'aliases': [],
        'iso2': 'UZ',
        'filter': 'uzbekistan',
        'capital': {
            'name': 'Tashkent',
            'geoPt': [
                41.2,
                69.18
            ]
        }
    },
    {
        'name': 'Vanuatu',
        'aliases': [],
        'iso2': 'VU',
        'filter': 'vanuatu',
        'capital': {
            'name': 'Port Vila',
            'geoPt': [
                -17.44,
                168.19
            ]
        }
    },
    {
        'name': 'Vatican City',
        'aliases': [
            'Holy See'
        ],
        'iso2': 'VA',
        'filter': 'vatican city holy see',
        'capital': {
            'name': 'Vatican City',
            'geoPt': [
                41.54,
                12.27
            ]
        }
    },
    {
        'name': 'Venezuela',
        'aliases': [],
        'iso2': 'VE',
        'filter': 'venezuela',
        'capital': {
            'name': 'Caracas',
            'geoPt': [
                10.3,
                -66.56
            ]
        }
    },
    {
        'name': 'Vietnam',
        'aliases': [],
        'iso2': 'VN',
        'filter': 'vietnam',
        'capital': {
            'name': 'Hanoi',
            'geoPt': [
                21.02,
                105.51
            ]
        }
    },
    {
        'name': 'Wales',
        'aliases': [],
        'iso2': 'GB-WLS',
        'filter': 'wales',
        'capital': {
            'name': 'Cardiff',
            'geoPt': [
                51.48, -3.17
            ]
        }
    },
    {
        'name': 'Wallis and Futuna',
        'aliases': [],
        'iso2': 'WF',
        'filter': 'wallis and futuna',
        'capital': {
            'name': 'Mata Utu',
            'geoPt': [
                -13.28, -176.17
            ]
        }
    },
    {
        'name': 'Western Sahara',
        'aliases': [],
        'iso2': 'EH',
        'filter': 'western sahara',
        'capital': {
            'name': 'El-Aaiun',
            'geoPt': [
                27.14,
                -13.19
            ]
        }
    },
    {
        'name': 'Yemen',
        'aliases': [],
        'iso2': 'YE',
        'filter': 'yemen',
        'capital': {
            'name': 'Sanaa',
            'geoPt': [
                15.21,
                44.12
            ]
        }
    },
    {
        'name': 'Zambia',
        'aliases': [],
        'iso2': 'ZM',
        'filter': 'zambia',
        'capital': {
            'name': 'Lusaka',
            'geoPt': [
                -15.25,
                28.17
            ]
        }
    },
    {
        'name': 'Zimbabwe',
        'aliases': [],
        'iso2': 'ZW',
        'filter': 'zimbabwe',
        'capital': {
            'name': 'Harare',
            'geoPt': [
                -17.5,
                31.03
            ]
        }
    },
    {
        'name': 'Åland Islands',
        'aliases': [
            'Aland Islands'
        ],
        'iso2': 'AX',
        'filter': 'åland islands aland islands',
        'capital': {
            'name': 'Mariehamn',
            'geoPt': [
                60.09,
                19.9
            ]
        }
    }
];

// Exported Variables
export const countries = [
    ...countriesAlpha
        .map(a => {
            return {
                "Name": a.name,
                "Capital": {
                    "DLST": 0,
                    "TD": 0,
                    "Flg": 0,
                    "Name": a.capital.name,
                    "GeoPt": a.capital.geoPt
                },
                "CountryCodes": {
                    "tld": a.iso2.toLowerCase(),
                    "iso3": "",
                    "iso2": a.iso2,
                    "fips": "",
                    "isoN": 0
                },
                "iso2": a.iso2,
                "FlagE": "",
                "Flag": "/assets/img/flags/" + a.iso2 + ".svg",
                "Filter": a.filter
            }
        })
        .sort((a, b) => countriesS4.indexOf(a.iso2) < countriesS4.indexOf(b.iso2) ? -1 : 1)
];